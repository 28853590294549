import React, {useContext} from "react";
import {SideNavbar} from "../theme/components";
import quentinPhoto from "../assets/images/photo-studio-2022-tshirt-compressed-squared-3.jpg";
import {ContactPopupContext} from "../contexts/contact-popup";

export default function SideNavbarContainer({...restProps}){
    const {setContactPopupOpen} = useContext(ContactPopupContext);

    return (
        <SideNavbar {...restProps}>

        <SideNavbar.Profile imageSrc={quentinPhoto} name={"Quentin Boulanger"} title={"FullStack Web Developer"} />
        <SideNavbar.NavItem name={"Who am I ?"} href={"#whoAmI"} iconCss={"mdi-account-question"} />
            <SideNavbar.NavItem name={"My background"} href={"#background"} iconCss={"mdi-image-filter-hdr"} />
            <SideNavbar.NavItem name={"My skills"} href={"#skills"} iconCss={"mdi-account-school"} />
        <SideNavbar.NavDropdown iconCss={"mdi-flask-outline"} name={"Experience Highlights"} href={"#myOutstandingExperiences"}>
            <SideNavbar.NavDropdownItem name={"FullStack Developer"} href={"#experienceDeveloper"} />
            <SideNavbar.NavDropdownItem name={"SmartBlocus"} href={"#experienceSmartBlocus"} />
            <SideNavbar.NavDropdownItem name={"Handy"} href={"#experienceHandy"} />
            <SideNavbar.NavDropdownItem name={"Exchange student"} href={"#experienceUSA"} />
            <SideNavbar.NavDropdownItem name={"Rock Band"} href={"#experienceRockBand"} />
            <SideNavbar.NavDropdownItem name={"Charity project"} href={"#experienceCharityProject"} />
        </SideNavbar.NavDropdown>
        <SideNavbar.NavItem name={"Why should you take me ?"} href={"#whyShouldYouTakeMe"} iconCss={"mdi-lightbulb-on-30"} />
        <SideNavbar.Button name={"Contact me"} onClick={() => setContactPopupOpen((popupOpen => ! popupOpen)) } />

    </SideNavbar>);
}