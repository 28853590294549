import React from "react";
import {Footer} from "../theme/components";

export default function FooterContainer({...restProps}){
    return (
        <Footer {...restProps}>
            <Footer.Copyright>
                Copyright © Quentin Boulanger 2022
            </Footer.Copyright>
        </Footer>
    );

}