import {Anchor, CookieBanner, ThemeWrapper} from "./theme/components";
import React, {useState} from "react";
import quentinPhoto from "./assets/images/photo-studio-2022-costume-compressed-squared.jpg";
import whoAmIImage from './assets/images/whoAmI.jpg';
import backTothePast from './assets/images/myStory.jpg';
import burkinaFasoPicture from './assets/images/burkinaFaso.jpg';
import rockBand from './assets/images/rockBand.jpg';
import usaFlag from './assets/images/usaFlag.jpg';
import Handy from './assets/images/handy.jpg';
import SmartBlocus from './assets/images/logoSmartBlocus.png';
import FullstackDeveloper from './assets/images/fullstackDeveloper.jpg';
import GoodDeveloper from './assets/images/goodDeveloper.jpg';
import {FooterContainer, SideNavbarContainer, TopNavbarContainer} from "./containers";
import {TextImageRow, Experiences, Skills} from "./components";
import {ContactPopupContext} from './contexts/contact-popup';
import {Modal} from './theme/components'

function App() {
    const [ContactPopupOpen, setContactPopupOpen] = useState(false);


    function navigateToHash(hash){
        window.location.hash = hash;
    }

    return (
        <ContactPopupContext.Provider value={{ContactPopupOpen, setContactPopupOpen}}>
            <div className={ContactPopupOpen ? "modal-open": ""}>
                <Modal modalOpen={ContactPopupOpen} setModalOpen={setContactPopupOpen}>
                    <Modal.Header setModalOpen={setContactPopupOpen} title={"Let's get in touch"}/>
                    <Modal.Body className="bg-white p-2">
                        <h4>Any question ? </h4>
                        <p>Feel free to contact me. <i>(I usually reply within 24 hours)</i> </p>
                        <p className="mb-0"><i className="mdi mdi-email text-primary"></i> <a href="mailto:quentin.boulanger@revowind.com" className="link-info">quentin.boulanger@revowind.com</a></p>
                        <p><i className="mdi mdi-linkedin text-primary"></i> <a href="https://www.linkedin.com/in/quentin-boulanger/" className="link-info">@quentin-boulanger</a></p>
                        <p>For a mission proposition, you can also reach me through <a href="https://www.malt.fr/profile/quentinboulanger" className="link-info">Malt</a>. </p>

                    </Modal.Body>
                    <Modal.Footer setModalOpen={setContactPopupOpen}/>

                </Modal>
                {/*<div className="language-selection-container">*/}
                {/*    <div className="text-center">*/}
                {/*        <div className="d-inline-block shadow border-r-30 bg-white p-4">*/}
                {/*            <div className="text-start text-primary">*/}
                {/*                <h1>*/}
                {/*                    <i>Select your language</i>*/}
                {/*                </h1>*/}
                {/*            </div>*/}
                {/*            <div className="row mt-3">*/}
                {/*                <div className="col-6 text-end">*/}
                {/*                    <button className="btn btn-gradient-primary rounded-pill">*/}
                {/*                        Français*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*                <div className="col-6">*/}
                {/*                    <button className="btn btn-gradient-primary rounded-pill">*/}
                {/*                        English*/}
                {/*                    </button>*/}
                {/*                </div>*/}


                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}



                {/*</div>*/}



                <ThemeWrapper>
                    <TopNavbarContainer/>
                    <ThemeWrapper.Body>
                        <SideNavbarContainer/>

                        <ThemeWrapper.MainPannel>
                            <ThemeWrapper.ContentWrapper>

                                <div className=" text-center bg-gradient-light-blue m-2 border-r-30 shadow p-2 py-sm-4 px-sm-5 ">
                                        <div className="row">
                                            <div className="col-sm-6 my-auto text-sm-end text-center">
                                                <img src={quentinPhoto} className="rounded-circle shadow mw-100 my-auto" width={"250px"}/>
                                            </div>
                                            <div className="col-sm-6 my-auto text-sm-start text-center">
                                                <div className="p-2 text-black text-center d-inline-block">
                                                    <h1 className=" fw-bold">
                                                        Quentin Boulanger
                                                    </h1>
                                                    <p>
                                                        <i>Freelance Developer & Entrepreneur</i>
                                                    </p>
                                                    <div>
                                                        <button onClick={() => {navigateToHash("#whoAmI");}} className=" btn btn-gradient-primary btn-rounded btn-icon m-1">
                                                            <i className="mdi mdi-account-question" ></i>
                                                        </button>
                                                        <button onClick={() => {navigateToHash("#skills");}} className="btn btn-gradient-secondary btn-rounded btn-icon m-1">
                                                            <i className="mdi mdi-account-school" ></i>
                                                        </button>
                                                        <button onClick={() => {navigateToHash("#myOutstandingExperiences");}} className="btn btn-gradient-info btn-rounded btn-icon m-1">
                                                            <i className="mdi mdi-flask-outline" ></i>
                                                        </button>
                                                        <button onClick={() => setContactPopupOpen(true)}
                                                                className="btn btn-gradient-danger btn-rounded btn-icon">
                                                            <i className="mdi mdi-email-open"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                                <div className="mx-sm-5 mx-2 my-2 my-md-3">
                                    <Anchor id={"whoAmI"}/>
                                    <TextImageRow >
                                        <TextImageRow.TextColumn >
                                            <TextImageRow.Title title={"Who am I ?"}/>
                                            <p>
                                                <strong>Passionate developer and entrepreneur</strong>, I build web platforms and applications. I am a <strong>Javascript (React & Node.js) expert</strong> but I am used to being challenged in other languages too (Python, PHP, C).
                                            </p>
                                            <p>
                                                My journey today allows me to manage entire projects from backend to frontend, including cloud management. For web platforms, I am used to
                                                working on Laravel (PHP) or Node.js for the backend, React and Javascript for the frontend and I'm skilled in cloud management on AWS, DigitalOcean and Infomaniak.
                                            </p>
                                            <p>
                                                Aware of the importance of clean and optimized code, I know how to develop innovative web platforms and applications that meet the needs of my clients.
                                            </p>
                                            <p>
                                                <strong>Other Strengths ? </strong> I am a proactive developer & I don't stop at the first barrier. Once I commit to a mission, I respect the terms and do my best to make my customers happy. If extra hours are needed to respect the deadline, I don't make it an issue.
                                            </p>
                                        </TextImageRow.TextColumn>
                                        <TextImageRow.Image image={whoAmIImage} />

                                    </TextImageRow>
                                    <Anchor id={"background"}/>
                                    <TextImageRow>
                                        <TextImageRow.Image image={backTothePast}/>
                                        <TextImageRow.TextColumn>
                                            <TextImageRow.Title title={"My background"}/>
                                            <p>
                                                Passionate about programming since my youngest age, I trained as a civil
                                                engineer specializing in computer science, programming & mechanics. I created
                                                several web platforms of which I managed the Frontend, the Backend and the Cloud.

                                            </p>
                                            <p>I founded 2 startups. One specializes in Machine Learning & hand gestures recognition. The other offered a collaborative platform with complex helping tools for students. I was mentored & trained by the
                                                CEO of a company with over 1000 employees.
                                            </p>
                                            <p>
                                                Today, I want to help startups and companies achieve their goals.

                                            </p>
                                        </TextImageRow.TextColumn>

                                    </TextImageRow>

                                    <Anchor id="skills"/>
                                    <Skills title={"My skills"}>
                                        <Skills.Group>
                                            <Skills.Skill color={"danger"}>
                                                <Skills.Front>
                                                    React
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"danger"}>
                                                <Skills.Front>
                                                    Node.JS
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"danger"}>
                                                <Skills.Front>
                                                    Laravel
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"danger"}>
                                                <Skills.Front>
                                                    Next
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"danger"}>
                                                <Skills.Front>
                                                    Bootstrap
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>
                                        </Skills.Group>

                                        <Skills.Group>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    Javascript
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    PHP
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    Python
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    C
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Expert
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    C++
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Begineer
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    Java
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    HTML
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"primary"}>
                                                <Skills.Front>
                                                    CSS
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                        </Skills.Group>

                                        <Skills.Group>
                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    AWS
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    Infomaniak
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    Jetbrains
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    TensorFlow
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    Pytorch
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    Linux
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                            <Skills.Skill color={"info"}>
                                                <Skills.Front>
                                                    Windows
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                        </Skills.Group>

                                        <Skills.Group>
                                            <Skills.Skill color={"success"}>
                                                <Skills.Front>
                                                    Machine Learning
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Intermediate
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"success"}>
                                                <Skills.Front>
                                                    Lean Startup
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"success"}>
                                                <Skills.Front>
                                                    Agile
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"success"}>
                                                <Skills.Front>
                                                    NN & CNN
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>

                                            <Skills.Skill color={"success"}>
                                                <Skills.Front>
                                                    PWA
                                                </Skills.Front>
                                                <Skills.Back>
                                                    Advanced
                                                </Skills.Back>
                                            </Skills.Skill>
                                        </Skills.Group>


                                    </Skills>

                                    <Anchor id="myOutstandingExperiences"/>

                                    <Experiences title={"Experience Highlights"} >
                                        <Anchor id={"experienceDeveloper"}/>
                                        <Experiences.Experience>
                                            <Experiences.Image image={FullstackDeveloper}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title title={"FullStack Developer (Freelance)"}/>
                                                <Experiences.Description>
                                                    Helped several companies achieve their web development goals (mainly Javascript and PHP)

                                                </Experiences.Description>
                                                <Experiences.YearsActive>
                                                    2022 - ...
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>

                                        <Anchor id={"experienceSmartBlocus"}/>
                                        <Experiences.Experience >
                                            <Experiences.Image image={SmartBlocus}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title title={"Founder & developer of SmartBlocus"}/>
                                                <Experiences.Description>
                                                    <ul>
                                                        <li>Founded a company and a community that helps students to
                                                            succeed in their studies & make them happier
                                                        </li>
                                                        <li>Developing a platform with a unique and advanced collaboration
                                                            system
                                                        </li>
                                                        <li>Creating and leading a highly motivated community committed to
                                                            building a better future
                                                        </li>
                                                        <li>Coached by the Yncubator & mentored by a business specialist</li>
                                                    </ul>

                                                </Experiences.Description>
                                                <Experiences.YearsActive>
                                                    2020 - 2022
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>

                                        <Anchor id={"experienceHandy"}/>
                                        <Experiences.Experience >
                                            <Experiences.Image image={Handy}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title title={"Creator of Handy, Founder of RevoWind SRL"}/>
                                                <Experiences.Description>
                                                    <ul>
                                                        <li>Founded a company that aimed to develop and sell Handy, a device
                                                            that allows people to control self-service kiosks with hand
                                                            gestures in the air
                                                        </li>
                                                        <li>Helped by the prestigious VentureLab Incubator & weekly mentored
                                                            by Manuel Pallage (CEO of NSI)
                                                        </li>
                                                    </ul>


                                                </Experiences.Description>
                                                <Experiences.YearsActive>
                                                    2017 - 2021
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>
                                        <hr className="my-3 text-primary bg-primary line"/>

                                        <Anchor id={"experienceUSA"}/>
                                        <Experiences.Experience >
                                            <Experiences.Image image={usaFlag}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title
                                                    title={"6 months in the United States as an exchange student"}/>
                                                <Experiences.YearsActive>
                                                    2017 - 2018
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>

                                        <Anchor id={"experienceRockBand"}/>
                                        <Experiences.Experience>
                                            <Experiences.Image image={rockBand}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title
                                                    title={"Leader, singer, pianist & guitarist of a Rock Band"}/>
                                                <Experiences.YearsActive>
                                                    2013 - 2017
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>

                                        <Anchor id={"experienceCharityProject"}/>
                                        <Experiences.Experience>
                                            <Experiences.Image image={burkinaFasoPicture}/>
                                            <Experiences.TextSlot>
                                                <Experiences.Title
                                                    title={"Developed a charity project in my school to help children in Burkina-Faso"}/>
                                                <Experiences.YearsActive>
                                                    2011 - 2012
                                                </Experiences.YearsActive>

                                            </Experiences.TextSlot>

                                        </Experiences.Experience>


                                    </Experiences>

                                    <Anchor id={"whyShouldYouTakeMe"}/>
                                    <TextImageRow>
                                        <TextImageRow.Image image={GoodDeveloper}/>
                                        <TextImageRow.TextColumn>
                                            <TextImageRow.Title title={"Why should you choose me ?"}/>
                                            <p>
                                                I will be proactive in the missions you give me, find innovant solutions when needed, and will always do my best to make your dreams come true.
                                            </p>
                                            <p>
                                                My experience in Laravel, React, Node.js, and Javascript will help you build robust and enjoyable platforms.
                                            </p>
                                            <p>
                                                I devote myself in the missions given to me. I respect the terms, and often go beyond what was expected.

                                            </p>
                                        </TextImageRow.TextColumn>


                                    </TextImageRow>
                                    <div className="text-center my-3">
                                        <button className="btn-gradient-primary btn rounded-pill btn-lg" onClick={() => setContactPopupOpen(true)}>Contact me</button>
                                    </div>

                                </div>


                            </ThemeWrapper.ContentWrapper>
                            <FooterContainer/>
                            <CookieBanner />
                        </ThemeWrapper.MainPannel>
                    </ThemeWrapper.Body>
                </ThemeWrapper>
                {
                    ((ContactPopupOpen) &&
                        (<div className="modal-backdrop fade show"></div>)
                    )

                }
            </div>



        </ContactPopupContext.Provider>
    )
        ;
}

export default App;
