import React from "react";

import {FlippingBubble} from "../../theme/components";


export default function Skills({title, children, ...restProps}) {
    return (
        <div className="row my-2">
            <div className="col-12">
                <div className="text-center my-3">
                    <div className="d-inline-block py-2 px-4 bg-white shadow rounded-pill text-primary">
                        <h1>
                            <i>{title}</i>
                        </h1>
                    </div>
                </div>
                <div className="mx-3 text-center" {...restProps}>
                    {children}
                </div>
            </div>
        </div>
    );
}

Skills.Group = function SkillsGroup({children, ...restProps}){
    return (
        <div className="my-2" {...restProps}>
            {children}
        </div>
    );
}

Skills.Skill = function SkillsSkill({children, color, ...restProps}) {
    return (<FlippingBubble className={`rounded-pill flip-bubble-${color}`} style={{width:"100px", height: "50px"}} {...restProps} >
        {children}
    </FlippingBubble>);
}

Skills.Front = function SkillsFront({children, ...restProps}) {
    return (<FlippingBubble.Front className="text-center rounded-pill"  {...restProps}>
        <p className="text-center p-0 m-0 position-relative top-50 translate-middle start-50">
        {children}
        </p>
    </FlippingBubble.Front>);
}
Skills.Back = function SkillsBack({children, ...restProps}) {
    return (
        <FlippingBubble.Back className="rounded-pill"  {...restProps}>
            <p className="text-center p-0 m-0 position-relative top-50 translate-middle start-50">
            {children}
            </p>
        </FlippingBubble.Back>);

}