import React, {useContext} from "react";
import {TopNavbar} from "../theme/components";
import {ContactPopupContext} from "../contexts/contact-popup";

export default function TopNavbarContainer({...restProps}){
    const {setContactPopupOpen} = useContext(ContactPopupContext);
    return(
        <TopNavbar {...restProps}>
            <TopNavbar.LogoGroup>
                <TopNavbar.LogoButton name={"Get in touch"} onClick={() => setContactPopupOpen(true)}/>
            </TopNavbar.LogoGroup>

            <TopNavbar.ContainerGroup>
                <TopNavbar.SideNavbarTogglerOnBigScreen />
                <TopNavbar.RightNav>
                    <TopNavbar.FullScreenToggler />
                </TopNavbar.RightNav>

                <TopNavbar.SideNavbarTogglerOnSmallScreen />
            </TopNavbar.ContainerGroup>
        </TopNavbar>
    );

}