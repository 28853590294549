import React, {useState} from "react";
import cookie from "cookie";

export default function CookieBanner(){

    const [cookiesConsented, setCookiesConsented] = useState(false);

    return(
        <div className={`cookie-banner p-2 text-center mb-0 shadow ${cookiesConsented ? "d-none": ""}`}>
            &#x1F36A; This website uses cookies to ensure you get the best experience.
            <button type="button" className="btn btn-primary btn-sm ms-3 rounded-pill" onClick={() => setCookiesConsented(true)}>
                I Got It
            </button>
        </div>
    );

}