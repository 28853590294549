import React, {useContext, useEffect, useState} from "react";
import {SideNavbarContext} from "../../contexts/side-navbar";

export default function SideNavbar({children, ...restProps}) {

    const { sidebarOpen} = useContext(SideNavbarContext);

    return (
        <nav className={`sidebar  sidebar-offcanvas shadow ${sidebarOpen ? "active" : ""}`} id="sidebar" {...restProps}>

            <div className={`position-fixed h-100 pb-3 ${sidebarOpen? "overflow-auto": ""}`} style={{width:"inherit"}}>
                <ul className="nav " >
                    {children}
                </ul>
            </div>

        </nav>
    );
}

SideNavbar.Profile = function SideNavbarProfile ({name, title, imageSrc  , ...restProps}){
    return(
        <li className="nav-item nav-profile" {...restProps}>
            <a href="#" className="nav-link">
                <div className="nav-profile-image">
                    <img src={imageSrc} alt="profile"/>
                </div>
                <div className="nav-profile-text d-flex flex-column">
                    <span className="font-weight-bold mb-2">{name}</span>
                    <span className="text-secondary text-small">{title}</span>
                </div>
            </a>
        </li>
    );
}

SideNavbar.NavItem = function SideNavbarNavItem({href, name, iconCss, ...restProps}){
    const [mouseHover, setMouseOver] = useState(false);

    const [active, setActive] = useState(false);


    const listenToScroll = () => {
        let elm = document.getElementById(href.substring(1));
        let rect = elm.getBoundingClientRect();
        let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        if(!(rect.bottom < -70 || rect.top - viewHeight >= -140)){
                setActive(true);

        }
        else{
                setActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll, { passive: true });
        listenToScroll();

        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
    }, [listenToScroll]);

    return(
        <li className={`nav-item ${active ? "active" : ""} ${mouseHover ? "hover-open" : ""}`} id={`sideNavbarItem${href.substring(1)}`}>
            <a className="nav-link" href={href} {...restProps} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} >
                <span className="menu-title">{name}</span>
                <i className={`mdi ${iconCss} menu-icon`}></i>
            </a>
        </li>
    );
}

SideNavbar.NavDropdown = function SideNavbarNavDropdown({children, name, iconCss, href, ...restProps}){

    const [showCollapse, setShowCollapse] = useState(false);
    const [mouseHover, setMouseOver] = useState(false);

    const [active, setActive] = useState(false);


    const listenToScroll = () => {
        let elm = document.getElementById(href.substring(1));
        let rect = elm.getBoundingClientRect();
        let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        if(!(rect.bottom < -70 || rect.top - viewHeight >= -140)){
            setActive(true);

        }
        else{
            setActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll, { passive: true });
        listenToScroll();

        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
    }, [listenToScroll]);

    return(
        <li className={`nav-item ${active ? "active" : ""} ${mouseHover ? "hover-open" : ""}`} onMouseEnter={() => {setMouseOver(true);setShowCollapse(true);}} onMouseLeave={() => {setMouseOver(false); setShowCollapse(false);}}>
            <a className="nav-link" data-bs-toggle="collapse" href={href}
            >
                <span className="menu-title">{name}</span>
                <i className="mdi mdi-menu-down"></i>
                <i className={`mdi ${iconCss} menu-icon`}></i>
            </a>
            <div className={`collapse ${showCollapse ? "show": ""}`} id="ui-basic">
                <ul className="nav flex-column sub-menu">
                    {children}
                </ul>
            </div>
        </li>
    );
}

SideNavbar.NavDropdownItem = function SideNavbarNavDropdownItem({href, name, ...restProps}){

    const [active, setActive] = useState(false);


    const listenToScroll = () => {
        let elm = document.getElementById(href.substring(1));
        let rect = elm.getBoundingClientRect();
        let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        if(!(rect.bottom < -70 || rect.top - viewHeight >= -140)){
            setActive(true);
        }
        else{
            setActive(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll, { passive: true });
        listenToScroll();

        return () => {
            window.removeEventListener('scroll', listenToScroll);
        };
    }, [listenToScroll]);

    return(
        <li className={`nav-item`} {...restProps}>
            <a className={`nav-link ${active ? "active" : ""}`} href={href}>
                {name}
            </a>
        </li>
    );
}

SideNavbar.Button = function SideNavbarButton({name, ...restProps}){
    return (
        <li className="nav-item sidebar-actions" {...restProps}>
        <button className="btn btn-block btn-lg btn-inverse-primary mt-4">{name}</button>
        </li>
    );
}