import React from "react";

export default function Modal({children, modalOpen, setModalOpen, ...restProps}) {

    return (
        <div className={`modal fade ${modalOpen ? "show d-block" : ""}`}  role="dialog" onClick={() => setModalOpen(false)}>
            {/* eslint-disable-next-line no-restricted-globals */}
            <div className="modal-dialog modal-dialog-centered" role="document" onClick={(e) => e.stopPropagation()}>
                <div className="modal-content" {...restProps}>
                    {children}

                </div>
            </div>
        </div>
    );
}

Modal.Header = function ModalHeader({setModalOpen, title, ...restProps}){
    return (<div className="modal-header" {...restProps}>
        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
        <button type="button" className="btn-close" onClick={() => setModalOpen(false)}>
        </button>
    </div>);
}

Modal.Body = function ModalBody({children, ...restProps}){
   return (<div className="modal-body" {...restProps}>
        {children}
    </div>);
}

Modal.Footer = function ModalFooter({setModalOpen,...restProps}){
    return (<div className="modal-footer" {...restProps}>
        <button type="button" className="btn btn-primary rounded-pill" data-dismiss="modal" onClick={() => setModalOpen(false)}>Close</button>
    </div>);
}