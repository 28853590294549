import React from "react";

export default function TextImageRow({children, ...restProps}){
    return (
        <div className="row my-2 p-2" {...restProps}>
            {children}

        </div>

    );
}

TextImageRow.TextColumn = function TextImageRowTextColumn({children, className, ...restProps}){
    return (
        <>
            <div className={`col-sm-8 border-r-30 bg-white ${className}`} {...restProps}>
                {children}
            </div>
        </>
    );
}

TextImageRow.Title = function TextImageRowTitle({title, ...restProps}){
    return (
        <div className="text-start mt-3 text-primary" {...restProps}>
            <h1>
                <i>{title}</i>
            </h1>
        </div>
    );
}

TextImageRow.Image = function TextImageRowImage({image, className, ...restProps}){
    return(
        <div className={`col-sm-4 text-center my-auto d-none d-sm-block ${className}`} {...restProps}>
            <img src={image} className="rounded-circle shadow mw-100 my-2" width={"250px"} />
        </div>
    );
}