import React from "react";

export default function Footer({children, ...restProps}) {
    return(
        <footer className="footer" {...restProps}>
            <div className="container-fluid d-flex justify-content-between">
                {children}
            </div>
        </footer>

    );
}

Footer.Copyright = function FooterCopyright({children, ...restProps}){
    return (
        <span className="text-muted d-block text-center text-sm-start d-sm-inline-block" {...restProps}>
            {children}
        </span>
    );

}


