import React from "react";


export default function FlippingBubble({children, className, ...restProps}) {
    return (
        <div className="d-inline-block mx-1">
            <div className={`flipping-bubble-container ${className}`}   {...restProps}>
                <div className={`flipping-bubble-card ${className}`}>
                    {children}
                </div>
            </div>
        </div>
        );
}
FlippingBubble.Front = function FlippingBubbleFront({children, className, ...restProps}) {
    return (<div className={`flipping-bubble-front ${className}`} {...restProps}>
        {children}
    </div>);
}
FlippingBubble.Back = function FlippingBubbleBack({children, className, ...restProps}) {
    return (<div className={`flipping-bubble-back ${className}`}    {...restProps}>
        {children}
    </div>);

}