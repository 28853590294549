import React from "react";

export default function Experiences({title, children, ...restProps}){

    return (
        <div className="row bg-white border-r-30 p-3 my-3">
            <div className="col-12">
                <div className="text-start my-3">
                        <h1 className="text-primary">
                            <i>{title}</i>
                        </h1>
                </div>
                <div className="mx-3 my-4" {...restProps}>
                    {children}
                </div>
            </div>
        </div>
        );

}

Experiences.Experience = function ExperiencesExperience({children, ...restProps}){
    return (
        <div className="row my-2" {...restProps}>
            {children}
        </div>
    );
}

Experiences.Image = function ExperiencesImage({image, ...restProps}){
    return (
        <>
        <div className="col-2 text-end my-auto d-none d-lg-block" {...restProps}>
            <img src={image} className="w-50 border-r-30 shadow" />

        </div>
            <div className="col-2 text-end my-auto p-0 d-none d-md-block d-lg-none" {...restProps}>
                <img src={image} className="w-75 border-r-30 shadow" />

            </div>
    <div className="col-2 text-end my-auto p-0 d-block d-md-none" {...restProps}>
        <img src={image} className="w-100 border-r-30 shadow" />

    </div>
            </>
    );
}

Experiences.TextSlot = function ExperiencesTextSlot({children, ...restProps}){
    return(<div className="col-10 my-auto" {...restProps}>
        {children}
    </div>);
}

Experiences.Title = function ExperiencesTitle({title, ...restProps}){
    return(<h3 {...restProps}>
        {title}
    </h3>);
}

Experiences.Description = function ExperiencesDescription({children, ...restProps}){
    return(<div {...restProps}>
        {children}

    </div>);
}

Experiences.YearsActive = function ExperiencesYearsActive({children, ... restProps}){
    return (<p className="fst-italic mt-3" {...restProps}>
        {children}

    </p>)
}