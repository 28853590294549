import React, {useState} from "react";
import {SideNavbarContext} from "../../contexts/side-navbar";

export default function ThemeWrapper({children, ...restProps}) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <SideNavbarContext.Provider value={{sidebarOpen, setSidebarOpen}}>
            <div className={sidebarOpen ? "" :"sidebar-icon-only" }>
                <div className="App" {...restProps}>
                    <div className="container-scroller">
                        {children}
                    </div>
                </div>
            </div>
        </SideNavbarContext.Provider>
    );

}

ThemeWrapper.Body = function ThemeWrapperBody({children, ...restProps}) {
    return (
        <div className="container-fluid page-body-wrapper" {...restProps}>

            {children}
        </div>
    );
}

ThemeWrapper.MainPannel = function ThemeWrapperMainPannel({children, ...restProps}){
    return(
        <div className="main-panel" {...restProps}>
            {children}
        </div>
    );
}

ThemeWrapper.ContentWrapper = function ThemeWrapperContentWrapper({children, ...restProps}){
    return(
        <div className="content-wrapper p-1 p-sm-4" {...restProps}>
            {children}
        </div>
    );
}